import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet, useTranslation } from "gatsby-plugin-react-i18next";

function META({ description, lang, meta, keywords }) {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                    }
                }
            }
        `
    );
    const {t} = useTranslation();

    const metaTitle = t('metadata.title') || site.siteMetadata.title;
    const metaDescription = t('metadata.description') || description || site.siteMetadata.description;

    return (
        <Helmet
            htmlAttributes={{
                lang
            }}
            title={metaTitle}
            meta={[
                {
                    name: "viewport",
                    content:
                        "width=device-width, initial-scale=1, viewport-fit=cover"
                },
                {
                    name: `description`,
                    content: metaDescription
                },
                {
                    property: `og:title`,
                    content: metaTitle
                },
                {
                    property: `og:description`,
                    content: metaDescription
                },
                {
                    property: `og:type`,
                    content: `website`
                },
                {
                    property: `og:image`,
                    content: `https://fireport.app/share_image_f.png`
                },
                {
                    name: `twitter:card`,
                    content: `summary`
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author
                },
                {
                    name: `twitter:title`,
                    content: metaTitle
                },
                {
                    name: `twitter:description`,
                    content: metaDescription
                },
                {
                    property: `twitter:image`,
                    content: `https://fireport.app/share_image_t.png`
                }
            ]
                .concat(
                    keywords.length > 0
                        ? {
                              name: `keywords`,
                              content: keywords.join(`, `)
                          }
                        : []
                )
                .concat(meta)}
        >
            <link
                href="https://fonts.googleapis.com/css?family=Roboto:100,300,400&amp;subset=latin-ext"
                rel="stylesheet"
            />
            <link
                href="https://fireport.app/"
                rel="canonical"
            />
        </Helmet>
    );
}

META.defaultProps = {
    lang: `cs`,
    meta: [],
    keywords: [],
    description: ``
};

META.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    keywords: PropTypes.arrayOf(PropTypes.string)
};

export default META;
