import "./../styles/normalize.scss";
import "./../styles/style.scss";

import React, { ReactElement } from "react";
import Header from "./header";
import Footer from "./footer";
import META from "./meta";

export interface LayoutProps {
    children: ReactElement;
}

export interface LayoutState {}

export default class Layout extends React.Component<LayoutProps, LayoutState> {
    constructor(props: LayoutProps) {
        super(props);
        this.state = {};
    }

    public render() {
        return (
            <>
                <META
                    keywords={[
                        `fireport`,
                        `aplikace`,
                        `mobilní`,
                        `mobil`,
                        `hasiči`,
                        `hasič`,
                        `svolávací`,
                        `systém`,
                        `mobilní aplikace`,
                        `svolávací systém`
                    ]}
                />
                <Header />
                <main>{this.props.children}</main>
                <Footer />
            </>
        );
    }
}
