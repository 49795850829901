import { Link, StaticQuery, graphql } from "gatsby";
import React from "react";
import Img from "gatsby-image";
import { Trans } from "gatsby-plugin-react-i18next";

const Footer = () => (
    <StaticQuery
        query={graphql`
            query {
                logoFireport: file(
                    relativePath: { eq: "footer_fireport.png" }
                ) {
                    childImageSharp {
                        fixed(height: 32) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                logoFrp: file(relativePath: { eq: "footer_frp.png" }) {
                    childImageSharp {
                        fixed(height: 32) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `}
        render={data => (
            <footer>
                <div className={"fireport"}>
                    <Img fixed={data.logoFireport.childImageSharp.fixed} />
                    <span>
                        <Trans>footer.alertingSystemFireport</Trans>
                        <br />
                        <Trans>footer.motto</Trans>
                    </span>
                </div>
                <div className={"legal"}>
                    Google Play and the Google Play logo are trademarks of
                    Google LLC.
                    <br />
                    Apple, the Apple logo, iPhone, and iPad are trademarks of
                    Apple Inc., registered in the U.S. and other countries and
                    regions. App Store is a service mark of Apple Inc.
                </div>
                <div className={"frp"}>
                    <span>
                        Copyright © {(new Date().getFullYear())}
                        <br />
                        FRP Services s.r.o.
                    </span>
                    <Img fixed={data.logoFrp.childImageSharp.fixed} />
                </div>
            </footer>
        )}
    />
);

export default Footer;
