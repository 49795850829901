import { Link, StaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Img from "gatsby-image";
import { Trans } from "gatsby-plugin-react-i18next";

const Header = () => (
    <StaticQuery
        query={graphql`
            query {
                logo: file(relativePath: { eq: "header_logo.png" }) {
                    childImageSharp {
                        fluid(maxHeight: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `}
        render={data => (
            <header>
                <Link to="/">
                    <Img fluid={data.logo.childImageSharp.fluid} />
                    <span>
                        <Trans>header.mobileApplication</Trans>
                        <br />
                        <strong><Trans>header.fireport</Trans></strong>
                    </span>
                </Link>
            </header>
        )}
    />
);

export default Header;
